import React from 'react';
import WithFade from '../hoc/WithFade';

import IMMachine from '../Styles/Assets/IMMachine.svg';
import theMakers from '../Styles/Assets/TheMakers.mp4';

const HomeSection3 = props => {
  // var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

  return <div id="the-makers" className='home-section section-3'>
    <div className="section-3_video-wrapper">
      <WithFade type="left">
        <div className='section-3_video-container'>
          <video playsInline className="section-3_video" autoPlay muted loop >
            <source src={theMakers} type="video/mp4"/>
          </video>
          {/* <div className='section-3_video-squares'>
            <div className='section-3_video-square blue1back'/>
            <div className='section-3_video-square blue2back'/>
            <div className='section-3_video-square blue3back'/>
            <div className='section-3_video-square blue4back'/>
            <div className='section-3_video-square blue5back'/>
            <div className='section-3_video-square blue6back'/>
            <div className='section-3_video-circle orangeback'/>
          </div>
          <h3>THE MAKERS</h3> */}
        </div>
      </WithFade>
    </div>
    
    <img className='section-3_image-medium_screens' src={IMMachine} alt={'The Idea Maker Machine'}/>
  </div>
}

export default HomeSection3;