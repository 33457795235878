import emailjs from 'emailjs-com';
import React, { useState } from 'react';

import logo from '../Styles/Assets/FlatLogo.svg';
import check from '../Styles/Assets/check-circle.svg';
import Loader from './SmallComponents/Loader';

const HomeSection6 = props => {
  const [templateParams, setTemplateParams] = useState({
    toEmail: 'maxdayton@sapainvestment.com',
    fromEmail: '',
    fromName: '',
    message: '',
  });

  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const onUpdateForm = (type, text) => {
    switch (type) {
      case 'email':
        setTemplateParams({...templateParams, fromEmail: text})
        break;
      case 'name': 
        setTemplateParams({...templateParams, fromName: text})
        break;
      case 'message':
        setTemplateParams({...templateParams, message: text})
        break;
      default:
        return;
    }
  }

  const onSendEmail = async () => {
    setLoading(true);
    if (templateParams.fromEmail !== '' && templateParams.fromName !== '' && templateParams.message !== '') {
      await emailjs.send('service_vozo6bg', 'template_0lfqdfq', templateParams, 'user_67bBrbmEMWkQ4ScbWAOMD').then((data) => {
        console.log('SUCCESS!', data);
        setEmailSent(true);
      }).catch((error) => {
        console.log('FAILURE...', error);
      })
    } else {
      alert('Please complete the form!')
    }
    setLoading(false);
  }

  return <div id="contact-us" className='home-section section-6'>
    <p style={{fontWeight: 'bold', marginBottom: -60}}>Let's talk</p><p>About your idea!</p>

    <div className='section-6_contact'>
      <div className='section-6_ball orangeback'/>
      <img className='section-6_logo' src={logo} alt="Idea Makers Logo"/>
      <input aria-label="Name" onChange={(e) => onUpdateForm('name', e.target.value)} placeholder="Name"/>
      <input aria-label="Email Address" onChange={(e) => onUpdateForm('email', e.target.value)} placeholder="Email"/>
      <textarea aria-label="Message" onChange={(e) => onUpdateForm('message', e.target.value)} placeholder="Message"/>
      <div className='contact_bottom'>
        {/* <i>or call us: <br/>1-800-123-4567</i> */}
        <button onClick={() => onSendEmail()}>{emailSent ? <img src={check} alt="Email Sent!" /> : loading ? <Loader /> : 'SUBMIT'}</button>
      </div>
    </div>
  </div>
}

export default HomeSection6;