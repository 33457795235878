import React from 'react';

import DownArrows from '../Styles/Assets/DownArrows.svg';

const HomeSection5 = props => {
  return <div className='home-section section-5'>
    <div className='section-5_funnel'>
      <h1>INDUSTRY EXPERIENCE</h1>
      {/* <img src={PressureTested} alt="Pressure Tested" className='pressure-tested' /> */}
      <img src={DownArrows} alt="pressure" className='down-arrows' />
      <ul className='section-5_list'>
        <li>Mechanical Hardware</li>
        <li>Electronics</li>
        <li>Medical Cannabis</li>
        <li>Health & Beauty</li>
        <li>Software</li>
        <li>Packaging</li>
        <li>Medical Devices</li>
        <li>Sporting Goods</li>
        <li>Plastic Goods</li>
        <li>Firearms Accessories</li>
        <li>Lifestyle & Apparel</li>
        <li>Plush Toys</li>
        <li>Automotive</li>
        <li>Soft Goods</li>
      </ul>
    </div>
  </div>
}

export default HomeSection5;