import React from 'react';

import logo from '../Styles/Assets/FlatLogo.svg';
import IdeasMade from '../Styles/Assets/IdeasMade.svg';
import linkedIn from '../Styles/Assets/linkedin.svg';
import facebook from '../Styles/Assets/facebook.svg';
import instagram from '../Styles/Assets/instagram.svg';

const Footer = props => {
  return <div className='footer'>
    <div className='footer_words'>
      {/* <h3 className='footer_slogan'>IDEAS: MADE</h3> */}
      <img src={logo} alt='logo'/>
      <i>a full-stack product design and digital marketing agency</i>
      <div className='footer_socials'>
        <a className='footer_socialLink' href="https://www.linkedin.com/company/idea-makers-inc/about/" target="_blank" rel="noreferrer"><img src={linkedIn} alt="LinkedIn" /></a>
        <a className='footer_socialLink' href="https://www.facebook.com/IdeaMakersInc" target="_blank" rel="noreferrer"><img src={facebook} alt="LinkedIn" /></a>
        <a className='footer_socialLink' href="https://www.instagram.com/ideamakersinc/" target="_blank" rel="noreferrer"><img src={instagram} alt="Instagram" /></a>
      </div>
      <div>
        <span>722 South State Street</span>
        <span>Salt Lake City, Utah</span>
        <span>84111</span>
      </div>
      <span className='footer_copyright'>© 2021 COPYRIGHT | ALL RIGHTS RESERVED</span>
    </div>
    <img className='footer_balls' src={IdeasMade} alt="Ideas:Made"/>
  </div>
}

export default Footer;