import React from 'react';

const MobileCard = (props) => {
    return (
        <div className="section-4_mobile-card">
            <div className="mobile-card_header">
                <span className="mobile-card_letter">{props.letter}.</span>
                <div className="mobile-card_header-text">
                    <h2 className="mobile-card_title">{props.title}</h2>
                    <h3 className="mobile-card_tagline">{props.tagline}</h3>
                </div>
            </div>
            <div className="mobile-card_main">
                <div className="mobile-card_description-container">
                    <p className="mobile-card_hook">{props.hook}</p>
                    <p className="mobile-card_description">{props.description}</p>
                </div>
                <div className="mobile-card_tags">
                    {props.tags.map(tag => (
                        <div key={tag} className="mobile-card_tag">
                            {tag}
                        </div>
                    ))}
                </div>
            </div>
            <div className="mobile-card_footer">
                <img className="mobile-card_image" src={props.pic} alt="Graphic" />
            </div>
        </div>
    )
};

export default MobileCard;