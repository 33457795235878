import React from 'react';
// import WithFade from '../hoc/WithFade';

// import logo2 from '../Styles/Assets/IM Logo.png';
import logo from '../Styles/Assets/FlatLogo.svg';

const Header = props => {
  return <div className = 'header'>
    {/* <WithFade type="top"> */}
      <a href="/">
        <img className='header_logo' src={logo} alt={'IM Logo'}/>
      </a>
    {/* </WithFade> */}
    {/* <WithFade type="top"> */}
      <nav className='header_nav'>
        {/* <a href="#home">HOME</a> */}
        <a href="#contact-us">GET STARTED</a>
        {/* <a href="#the-makers">ABOUT</a>
        <a href="#our-recipe">SERVICES</a> */}
        {/* <a>WORK</a> */}
      </nav>
    {/* </WithFade> */}
  </div>
}

export default Header;