import React from 'react';
import WithFade from '../hoc/WithFade';

import illustration from '../Styles/Assets/Illustration.svg';

const HomeSection2 = props => {
	return <div className='home-section section-2'>
		<WithFade type='bottom'>
			<img className='section-2_image' src={illustration} alt={'Sketch'}/>
		</WithFade>
		<div className='home-text section-2_text'>
			<WithFade type="bottom">
				{/* <h2>We're ready when you are</h2> */}
				<h2>We Launch Your Ideas Forward</h2>
				{/* <h3>{'< your idea needs us >'}</h3> */}
				<h3 className='section-2_tagline' >{'< To the Moon >'}</h3>
				<p>
					What sets us apart? We build unforgettable brands AND we avoid using any copy-and-paste business strategies. Your ideas are unique, and so is our process. We think critically about your next steps so we can get you a customized plan of action. Get your dream product in the hands of consumers quickly and boldly with us. Here’s the team behind the machine:
				</p>
			</WithFade>
		</div>
	</div>
}

export default HomeSection2;