import React from 'react';

import Card from './Section4Card';
import MobileCard from './MobileCard';

import picA from '../Styles/Assets/Dinner.svg';
import picB from '../Styles/Assets/ConveyorBelt.svg';
import picC from '../Styles/Assets/JumpStart.svg';

import logo from '../Styles/Assets/FlatLogo.svg'

const HomeSection4 = props => {
  return <div id="our-recipe" className='home-section section-4'>
    <h2 style={{zIndex: 10000000}}>Our Recipe</h2>
    <h3 style={{zIndex: 10000000}}>Custom-baked to your needs</h3>
    
    <Card
      letter="A"
      hook="The full Idea Makers experience. We will get you over the finish line." 
      description="Our in-house product designers(with 20 years of experience) will help you
      develop your ideas into tangible products. We understand this is an intimate
      experience, and we will work closely with you to ensure it's exactly how
      you want it created."
      title="Just an idea on a napkin."
      tagline={'< idea energizer >'}
      statements='Product Innovation. Product Interview. Prototypes. 
      Patents. Tooling and Molding. Scaling. Research and Development.'
      pic={picA}
    />

    <Card
      letter="B"
      hook="We find your business' hurdles and get you over them."
      description="We have access to and control over advanced product facilities
      that are ready to make your final product to any order size you'd like. We 
      work to keep the per-unit cost low without sacrificing quality. We identify
       your budget, timeline, and gameplan of your products. We find your 
       business' hurdles and get you over them."
      title="Strategic scaling needs."
      tagline={'< business accelerator >'}
      statements='Budgeting. Networking. Placing Orders. Sales Growth. Distribution
      and Logistics. Assembly. Creating Long-Term Value. Business Development.'
      pic={picB}
    />

    <Card
      letter="C"
      hook="We can teach old dogs new tricks."
      description="Sometimes just a little tweaking is all you need. Other times,
       you need a full makeover. Either way, we create meaningful brands that 
       consumers will recognize and trust. Our award-winning marketing strategies
       can teach old dogs new tricks."
      title="Pivot and revitalize"
      tagline={'< brand booster >'}
      statements='Digital Strategies. Brand Identity. Social Media Management. SEO.
      E-Commerce Setup. Marketing. Brand Language. Style Guides.'
      pic={picC}
    />

    <MobileCard 
      letter="A"
      hook="The full Idea Makers experience. We will get you over the finish line." 
      description="Our in-house product designers(with 20 years of experience) will help you
      develop your ideas into tangible products. We understand this is an intimate
      experience, and we will work closely with you to ensure it's exactly how
      you want it created."
      title="Just an idea..."
      tagline={'< idea energizer >'}
      tags={['Product Innovation', 'Product Interview', 'Prototypes', 
        'Patents', 'Tooling and Molding', 'Scaling', 'Research and Development']}
      pic={picA}
    />
    <MobileCard 
      letter="B"
      hook="We find your business' hurdles and get you over them."
      description="We have access to and control over advanced product facilities
      that are ready to make your final product to any order size you'd like. We 
      work to keep the per-unit cost low without sacrificing quality. We identify
       your budget, timeline, and gameplan of your products. We find your 
       business' hurdles and get you over them."
      title="Strategic scaling."
      tagline={'< business accelerator >'}
      tags={['Budgeting', 'Networking', 'Placing Orders', 'Sales Growth', 'Distribution and Logistics',
      'Assembly', 'Creating Long-Term Value', 'Business Development']}
      pic={picB}
    />
    <MobileCard 
      letter="C"
      hook="We can teach old dogs new tricks."
      description="Sometimes just a little tweaking is all you need. Other times,
       you need a full makeover. Either way, we create meaningful brands that 
       consumers will recognize and trust. Our award-winning marketing strategies
       can teach old dogs new tricks."
      title="Pivot & revitalize."
      tagline={'< brand booster >'}
      tags={['Digital Strategies', 'Brand Identity', 'Social Media Management', 'SEO',
      'E-Commerce Setup', 'Marketing', 'Brand Language', 'Style Guides']}
      pic={picC}
    />

    <div className='section-5_top'>
      <img className='section-5_logo' src={logo} alt="Idea Makers Logo"/>
      <span>...and everything falls into place</span>
    </div>
  </div>
}

export default HomeSection4;