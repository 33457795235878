import React, {useState} from 'react';

import BlackFlip from '../Styles/Assets/blackFlip.svg';
import OrangeFlip from '../Styles/Assets/orangeFlip.svg';

const Section4Card = props => {

  const [showFront, setShowFront] = useState(true);

  return  <div className='scene'>
    {/* <WithFade type="bottom"> */}
      <div 
        className={showFront ? 'section-4_card' : 'section-4_card is-flipped'}
        onClick={()=>showFront?setShowFront(false):setShowFront(true)}>

  {/* === === CARD FRONT === === */}

        <div className='card_inner card-1'>
          <div className='card_top'>
            <span className='card_letter'>{props.letter}.</span>
            <div className='card_text'>
              <h2>{props.title}</h2>
              <h3>{props.tagline}</h3>
              <p className='card_hook'>{props.hook}</p>
            </div>
          </div>

          <div className='card_bottom'>
            <img className='card_flip' src={BlackFlip} alt="flip"/>
            <img className='card_image' src={props.pic} alt="graphic"/>
          </div>
        </div>

  {/* === === CARD BACK === === */}

        <div className='card_inner card-2'>
          <div className='card_top'>
            <span className='card_letter'>{props.letter}.</span>
            <div className='card_text'>
              <p className='card_hook-2'>
                {props.description}
              </p>
            </div>
          </div>

          <h3 className='card_statements'>
            {props.statements}
          </h3>

          <div className='card_bottom'>
            <img className="orangeFlip" src={OrangeFlip} alt="flip"/>
          </div>
        </div>
      </div>
    {/* </WithFade> */}
  </div>

}

export default Section4Card;