import React from 'react';
import WithFade from '../hoc/WithFade';

import logo from '../Styles/Assets/3dLogo.svg';

const HomeSection1 = props => {
  return <div id="home" className='home-section section-1'>
  <div className='home-text section-1_text'>
    <WithFade type="left">
        <h2>Recipe For Disruption and Innovation</h2>
        <h3>{'< Welcome to the Idea Makers Machine >'}</h3>
        <p>
          We’ve got the process down. Consider us your powerhouse product development and marketing team. Our decades of experience matched with your great business ideas will break down industry walls and impress your customers. Here’s how we do it: 
        </p>
    </WithFade>
  </div>
  <div className="section-1_image">
    <WithFade type="right">
      <img src={logo} alt={'3D Idea Makers Logo'}/>
    </WithFade>
  </div>
</div>
}

export default HomeSection1;