import React from 'react';
// import routes from './routes';
import './Styles/style.scss';

import Header from './Components/Header';
import HomePage from './Components/HomePage';

function App() {
  return (<div className='app'>
      <Header/>
      {/* {routes} */}
      <HomePage />
  </div>
  );
}

export default App;
